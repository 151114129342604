import {ScriptGroupsName, UserCertificateInfo} from '@innovations28/e-sign';
import {UntilDestroy} from '@ngneat/until-destroy';

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';

import {BlockUntilResponseService} from '@core/services/services/local/block-until-response.service';

@UntilDestroy()
@Component({
  selector: 'app-e-sing-reader',
  templateUrl: './e-sing-reader.component.html',
})
export class ESingReaderComponent implements OnInit {
  @Output() userCertificateInfo = new EventEmitter<UserCertificateInfo>();

  selectedScriptGroup = this.formBuilder.control(ScriptGroupsName.JS_EU_SIGN_LIB);

  scriptGroupNames = ScriptGroupsName;

  constructor(
    private formBuilder: FormBuilder,
    public blockUntilResponseService: BlockUntilResponseService,
  ) {}

  ngOnInit(): void {
    this.blockUntilResponseService.blockState$.subscribe((value) => {
      if (value) {
        this.selectedScriptGroup.disable();
      } else {
        this.selectedScriptGroup.enable();
      }
    });
  }

  handleUserCertificateInfo(userCertificate: UserCertificateInfo) {
    this.userCertificateInfo.emit(userCertificate);
  }
}
