<h2 mat-dialog-title>Введіть номер телефону</h2>
<mat-dialog-content>
  <mat-form-field fxFlex>
    <mat-label>Номер телефону (+380...)</mat-label>
    <input
      matInput
      [(ngModel)]="data.phoneNumber"
      placeholder="+380..."
      prefix="+380"
      mask="(00) 000 00 00"
      required
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Відміна</button>
  <button color="primary" mat-flat-button [mat-dialog-close]="data.phoneNumber">Далі</button>
</mat-dialog-actions>
