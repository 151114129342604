<mat-button-toggle-group name="scriptGroupName" [formControl]="selectedScriptGroup">
  <mat-button-toggle [value]="scriptGroupNames.JS_EU_SIGN_LIB">Файл ЕЦП</mat-button-toggle>
  <mat-button-toggle [value]="scriptGroupNames.TOKEN_EU_SIGN_LIB">Апаратний ключ</mat-button-toggle>
</mat-button-toggle-group>
<app-file-e-sign
  *ngIf="selectedScriptGroup.value === scriptGroupNames.JS_EU_SIGN_LIB"
  (userCertificateInfo)="handleUserCertificateInfo($event)"
></app-file-e-sign>
<app-hardware-e-sign
  *ngIf="selectedScriptGroup.value === scriptGroupNames.TOKEN_EU_SIGN_LIB"
  (userCertificateInfo)="handleUserCertificateInfo($event)"
>
</app-hardware-e-sign>
