import {AsyncValidatorFn, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';

import {isObjectType} from '@core/commons/utils';
import {
  AuthType,
  FileESignFormFields,
  HardwareESignFormFields,
  RegistrationFields,
  SignInDataFields,
} from '@core/types';

export const PHONE_PREFIX = '+380';
export const PHONE_REG_EXP = /^\d{9}$/g;
export const PASSPORT_SERIAL_REG_EXP = /^[А-Я]{2}$/g;
export const PASSPORT_NUMBER_REG_EXP = /(^\d{6}$)|(^\d{9}$)/g;
export const CONFIRMATION_CODE_REG_EXP = /^\d{6}$/g;

export type ValidationType = {[key: string]: ValidatorFn[] | ValidatorFn | ValidationType};
export type AsyncValidationType = {
  [key: string]: AsyncValidatorFn[] | AsyncValidatorFn | AsyncValidationType;
};

export const createFormGroupFromConfig = (
  formBuilder: FormBuilder,
  enumWithFormFields: Object,
  valueObject: Object = {},
  validatorObject: ValidationType = {},
  asyncValidatorObject: AsyncValidationType = {},
): FormGroup => {
  const controlsConfig = Object.entries(enumWithFormFields).reduce(
    (config, [objectKey, objectValue]) => {
      if (isObjectType(objectValue)) {
        return {
          ...config,
          [objectKey]: createFormGroupFromConfig(
            formBuilder,
            objectValue,
            valueObject[objectKey],
            validatorObject[objectKey] as ValidationType,
            asyncValidatorObject[objectKey] as AsyncValidationType,
          ),
        };
      }
      return {
        ...config,
        [objectValue]: [
          valueObject[objectValue] ?? null,
          validatorObject[objectValue] ?? null,
          asyncValidatorObject[objectValue] ?? null,
        ],
      };
    },
    {},
  );
  return formBuilder.group(controlsConfig);
};

export const buildFileESignForm = (formBuilder: FormBuilder): FormGroup =>
  createFormGroupFromConfig(
    formBuilder,
    FileESignFormFields,
    {},
    {
      [FileESignFormFields.CERTIFICATE_AUTHORITY]: Validators.required,
      [FileESignFormFields.E_SIGN_FILE]: Validators.required,
      [FileESignFormFields.PASSWORD]: Validators.required,
    },
  );

export const buildHardwareESignForm = (formBuilder: FormBuilder): FormGroup =>
  createFormGroupFromConfig(
    formBuilder,
    HardwareESignFormFields,
    {},
    {
      [HardwareESignFormFields.MEDIA_TYPE]: Validators.required,
      [HardwareESignFormFields.MEDIA_DEVICE]: Validators.required,
      [HardwareESignFormFields.CERTIFICATE_AUTHORITY]: Validators.required,
      [HardwareESignFormFields.PASSWORD]: Validators.required,
    },
  );

export const buildUserRegistrationForm = (
  formBuilder: FormBuilder,
  innRequired = false,
): FormGroup =>
  createFormGroupFromConfig(
    formBuilder,
    RegistrationFields,
    {},
    {
      [RegistrationFields.FIRST_NAME]: Validators.required,
      [RegistrationFields.MIDDLE_NAME]: Validators.required,
      [RegistrationFields.LAST_NAME]: Validators.required,
      [RegistrationFields.BIRTH_DATE]: Validators.required,
      [RegistrationFields.MOBILE_PHONE]: [Validators.required, Validators.pattern(PHONE_REG_EXP)],
      [RegistrationFields.PASSWORD]: Validators.required,
      [RegistrationFields.PASSPORT_SERIAL]: [Validators.pattern(PASSPORT_SERIAL_REG_EXP)],
      [RegistrationFields.PASSPORT_NUMBER]: [
        Validators.required,
        Validators.pattern(PASSPORT_NUMBER_REG_EXP),
      ],

      [RegistrationFields.PASSPORT_ISSUER]: Validators.required,
      [RegistrationFields.INN]: innRequired ? Validators.required : [],
      [RegistrationFields.CODE]: [
        Validators.required,
        Validators.pattern(CONFIRMATION_CODE_REG_EXP),
      ],
    },
  );

export const buildESignForm = (formBuilder: FormBuilder, authType: AuthType): FormGroup =>
  createFormGroupFromConfig(
    formBuilder,
    SignInDataFields,
    {
      [SignInDataFields.AUTH_TYPE]: authType,
      [SignInDataFields.GRANT_TYPE]: 'password',
    },
    {
      [SignInDataFields.AUTH_TYPE]: Validators.required,
      [SignInDataFields.GRANT_TYPE]: Validators.required,
      [SignInDataFields.CLIENT_ID]: Validators.required,
      [SignInDataFields.CLIENT_CLASSIFIER]: Validators.required,
      [SignInDataFields.INN]: authType === AuthType.EDS ? [Validators.required] : [],
      [SignInDataFields.PASSWORD]: authType === AuthType.EDS ? [Validators.required] : [],
      [SignInDataFields.UUID]: authType === AuthType.PHONE ? [Validators.required] : [],
      [SignInDataFields.CODE]:
        authType === AuthType.PHONE
          ? [Validators.required, Validators.pattern(CONFIRMATION_CODE_REG_EXP)]
          : [],
    },
  );

export const confirmPasswordValidator: ValidatorFn = (formGroup: FormGroup) => {
  const confirmPasswordFormGroup = formGroup.get(RegistrationFields.CONFIRM_PASSWORD);

  const isValid =
    formGroup.get(RegistrationFields.PASSWORD).value === confirmPasswordFormGroup.value;

  confirmPasswordFormGroup.setErrors(isValid ? null : {notEquals: true});

  return null;
};
