import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import {AuthorizationService} from '@core/services/services/authorization.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthorizationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAuthorization();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAuthorization();
  }

  private checkAuthorization(): Observable<boolean> {
    return this.authService.isAuthorized().pipe(
      tap((isAuthorized) => {
        if (!isAuthorized) {
          this.router.navigate(['/login']);
        }
      }),
    );
  }
}
