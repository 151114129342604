import {ESignModule} from '@innovations28/e-sign';
import {LayoutModule} from '@layout/layout.module';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';

import {LOCALE_ID, NgModule} from '@angular/core';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {CoreModule} from '@core/core.module';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DeviceDetectorService} from 'ngx-device-detector';
import {UniversalDeviceDetectorService} from '@core/services/services/universal-device-detector.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    MatSnackBarModule,
    LayoutModule,
    RecaptchaV3Module,
    ESignModule.forRoot(),
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      },
    },
    {provide: LOCALE_ID, useValue: 'uk-UA'},
    {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey},
    {
      provide: DeviceDetectorService,
      useClass: UniversalDeviceDetectorService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
