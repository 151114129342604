import {LayoutComponent} from '@layout/layout.component';

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '@core/services/guards/auth.guard';
import {HeaderLayoutComponent} from '@shared/components/header-layout/header-layout.component';

const routes: Routes = [
  {
    component: HeaderLayoutComponent,
    path: 'external-login',
    loadChildren: () =>
      import('@pages/external-login/external-login.module').then((m) => m.ExternalLoginModule),
  },
  {
    component: HeaderLayoutComponent,
    path: 'login',
    loadChildren: () => import('@pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    component: HeaderLayoutComponent,
    path: 'registration',
    loadChildren: () =>
      import('./pages/registration/registration.module').then((m) => m.RegistrationModule),
  },
  {
    component: HeaderLayoutComponent,
    path: 'school-worker-registration',
    loadChildren: () =>
      import('./pages/school-worker-registration/school-worker-registration.module').then(
        (m) => m.SchoolWorkerRegistrationModule,
      ),
  },
  {
    component: HeaderLayoutComponent,
    path: 'rtg-worker-registration',
    loadChildren: () =>
      import('./pages/rtg-worker-registration/rtg-worker-registration.module').then(
        (m) => m.RtgWorkerRegistrationModule,
      ),
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/settings/settings.module').then((m) => m.SettingsModule),
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'id-gov-callback',
    loadChildren: () =>
      import('./pages/id-gov-callback/id-gov-callback.module').then((m) => m.IdGovCallbackModule),
  },
  {path: '**', redirectTo: 'dashboard'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
