import {BehaviorSubject, Observable, timer} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';

import {PHONE_PREFIX, PHONE_REG_EXP} from '@core/commons/forms';
import {AuthorizationService} from '@core/services/services/authorization.service';
import {BlockUntilResponseService} from '@core/services/services/local/block-until-response.service';
import {NotificationService} from '@core/services/services/notification.service';

export enum MobileFormFields {
  MOBILE_PHONE = 'mobilePhone',
  UUID = 'uuid',
  CODE = 'code',
}

export interface MobileLogin {
  [MobileFormFields.MOBILE_PHONE]: string;
  [MobileFormFields.UUID]: string;
  [MobileFormFields.CODE]: string;
}

@Component({
  selector: 'app-mobile-phone-login',
  templateUrl: './mobile-phone-login.component.html',
  styleUrls: ['./mobile-phone-login.component.scss'],
})
export class MobilePhoneLoginComponent implements OnInit {
  @Output() mobileLoginData = new EventEmitter<MobileLogin>();

  @ViewChild(MatStepper) stepper: MatStepper;

  mobileFormFields = MobileFormFields;

  form;

  private _timerSubject$ = new BehaviorSubject<number>(0);
  timer: Observable<number> = this._timerSubject$.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private authorizationService: AuthorizationService,
    public blockUntilResponseService: BlockUntilResponseService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      [MobileFormFields.MOBILE_PHONE]: [null, Validators.pattern(PHONE_REG_EXP)],
      [MobileFormFields.UUID]: [null, Validators.required],
      [MobileFormFields.CODE]: [null, Validators.required],
    });
  }

  get mobilePhoneField(): FormControl {
    return this.form.get(MobileFormFields.MOBILE_PHONE) as FormControl;
  }

  get uuidField(): FormControl {
    return this.form.get(MobileFormFields.UUID) as FormControl;
  }

  get isItCodeConfirmationStep(): boolean {
    const codeConfirmationStepIndex = 1;
    return this.stepper.selectedIndex === codeConfirmationStepIndex;
  }

  sendConfirmationCode() {
    this.authorizationService
      .sendSignInPhoneConfirmationCode(`${PHONE_PREFIX}${this.mobilePhoneField.value}`)
      .pipe(
        this.blockUntilResponseService.execute,
        filter((data) => !!data),
      )
      .subscribe(({uuid, message}) => {
        if (uuid) {
          this.uuidField.setValue(uuid);
          this.notificationService.notify(message || 'Код надісланно');
          this.stepper.next();
          this.setTimer(60);
        }
      });
  }

  sendConfirmationCodeInSms() {
    this.setTimer(60);
    this.authorizationService
      .sendSignInPhoneSmsConfirmationCode(`${PHONE_PREFIX}${this.mobilePhoneField.value}`)
      .pipe(
        this.blockUntilResponseService.execute,
        filter((data) => !!data),
      )
      .subscribe(({uuid, message}) => {
        if (uuid) {
          this.uuidField.setValue(uuid);
          this.notificationService.notify(message || 'Код надісланно в смс');
        }
      });
  }

  private setTimer(seconds: number): void {
    timer(0, 1000)
      .pipe(
        map((i) => seconds - i),
        take(seconds + 1),
      )
      .subscribe((currentSeconds) => this._timerSubject$.next(currentSeconds));
  }

  signIn() {
    this.mobileLoginData.emit(this.form.value);
  }
}
