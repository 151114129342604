import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule, Optional, SkipSelf} from '@angular/core';

import {ApiResponseHandlerInterceptor} from '@core/services/interceptors/api-response-handler-interceptor';
import {AuthTokenInterceptor} from '@core/services/interceptors/auth-token-interceptor';
import {BackendUrlInterceptor} from '@core/services/interceptors/backend-url-interceptor';

export const HttpInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: BackendUrlInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: ApiResponseHandlerInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true},
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [HttpInterceptorProviders],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
