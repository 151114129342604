import {CrystalLightboxModule} from '@crystalui/angular-lightbox';
import {NgxMaskModule} from 'ngx-mask';

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {RouterModule} from '@angular/router';

import {ESingReaderComponent} from '@shared/components/e-sing-reader/e-sing-reader.component';
import {MobilePhoneLoginComponent} from '@shared/components/mobile-phone-login/mobile-phone-login.component';
import {MaterialModule} from '@shared/modules/material.module';

import {ESignInfoModalComponent} from './components/e-sign-info-modal/e-sign-info-modal.component';
import {FileESignComponent} from './components/file-e-sign/file-e-sign.component';
import {HardwareESignComponent} from './components/hardware-e-sign/hardware-e-sign.component';
import {HeaderLayoutComponent} from './components/header-layout/header-layout.component';
import {HeaderComponent} from './components/header/header.component';
import {PasswordRecoveryModalComponent} from './components/pssword-recovery-modal/password-recovery-modal.component';
import {RegistrationSuspendedModalComponent} from './components/registration-suspended-modal/registration-suspended-modal.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {QRCodeModule} from 'angularx-qrcode';

@NgModule({
  declarations: [
    MobilePhoneLoginComponent,
    ESingReaderComponent,
    HeaderComponent,
    HeaderLayoutComponent,
    FileESignComponent,
    HardwareESignComponent,
    ESignInfoModalComponent,
    PasswordRecoveryModalComponent,
    RegistrationSuspendedModalComponent,
    LoadingSpinnerComponent,
    QrCodeComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    NgxMaskModule.forRoot(),
    CrystalLightboxModule,
    MatAutocompleteModule,
    FormsModule,
    QRCodeModule,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    MobilePhoneLoginComponent,
    ESingReaderComponent,
    HeaderComponent,
    NgxMaskModule,
    ESignInfoModalComponent,
    MatAutocompleteModule,
    PasswordRecoveryModalComponent,
    RegistrationSuspendedModalComponent,
    LoadingSpinnerComponent,
    QrCodeComponent,
    QRCodeModule,
  ],
})
export class SharedModule {}
