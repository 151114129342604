import {Observable} from 'rxjs';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParameterCodec,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from 'src/environments/environment';

export class CustomQueryEncoderHelper implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }
  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }
  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}

@Injectable()
export class BackendUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('assets')) {
      return next.handle(req);
    }
    if (req.url.startsWith('http') || req.url.startsWith('https')) {
      return next.handle(req);
    }
    const reqToBackEnd = req.clone({
      url: `${environment.backEndApiUrl}${req.url}`,
      params: this.encodeUrlParams(req),
    });
    return next.handle(reqToBackEnd);
  }

  // bug https://github.com/angular/angular/issues/11058
  private encodeUrlParams(req: HttpRequest<any>): HttpParams {
    const oldParams = req.params;
    if (oldParams.keys().length !== 0) {
      let newParams = new HttpParams({encoder: new CustomQueryEncoderHelper()});
      oldParams.keys().forEach((key) => {
        newParams = newParams.append(key, oldParams.get(key));
      });
      return newParams;
    }
    return oldParams;
  }
}
