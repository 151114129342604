<mat-vertical-stepper [linear]="true">
  <mat-step [stepControl]="uuidField" [editable]="false">
    <ng-template matStepLabel>Мобільний номер телефону</ng-template>
    <div fxLayout="row" fxLayoutGap="16px">
      <mat-form-field fxFlex>
        <mat-label>Номер телефону (+380...)</mat-label>
        <input
          matInput
          [formControl]="mobilePhoneField"
          placeholder="+380..."
          prefix="+380"
          mask="(00) 000 00 00"
          required
        />
      </mat-form-field>
      <div fxFlexAlign="center">
        <button
          mat-raised-button
          color="primary"
          [disabled]="!mobilePhoneField.valid || (blockUntilResponseService.blockState$ | async)"
          (click)="sendConfirmationCode()"
        >
          Далі
        </button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="form" [editable]="false">
    <form [formGroup]="form" fxLayout="row" fxLayoutGap="16px">
      <ng-template matStepLabel>Код підтвердження</ng-template>
      <mat-form-field fxFlex>
        <mat-label>Код підтвердження</mat-label>
        <input matInput [formControlName]="mobileFormFields.CODE" required />
      </mat-form-field>
      <div fxFlexAlign="center">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="signIn()">
          Увійти
        </button>
      </div>
    </form>
    <button
      fxFlexAlign="end"
      mat-button
      color="primary"
      [disabled]="
        !mobilePhoneField.valid ||
        (blockUntilResponseService.blockState$ | async) ||
        !!(timer | async)
      "
      (click)="sendConfirmationCodeInSms()"
    >
      Повторно надіслати код в смс {{ !(timer | async) ? '' : '(' + (timer | async) + ')' }}
    </button>
  </mat-step>
</mat-vertical-stepper>
