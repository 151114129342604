<form [formGroup]="form" fxLayout="column">
  <mat-form-field>
    <mat-label>Тип носія</mat-label>
    <input
      type="text"
      matInput
      [formControlName]="formFields.MEDIA_TYPE"
      [matAutocomplete]="mediaTypeAutocomplete"
    />
    <mat-autocomplete #mediaTypeAutocomplete="matAutocomplete" [displayWith]="displayIdNameFn">
      <mat-option *ngFor="let mediaType of mediaTypes$ | async" [value]="mediaType">
        {{ mediaType.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Назва носія</mat-label>
    <input
      type="text"
      matInput
      [formControlName]="formFields.MEDIA_DEVICE"
      [matAutocomplete]="mediaDeviceAutocomplete"
    />
    <mat-autocomplete #mediaDeviceAutocomplete="matAutocomplete" [displayWith]="displayIdNameFn">
      <mat-option *ngFor="let mediaDevice of mediaDevices$ | async" [value]="mediaDevice">
        {{ mediaDevice.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Центр сертифікації</mat-label>
    <input
      type="text"
      matInput
      [formControlName]="formFields.CERTIFICATE_AUTHORITY"
      [matAutocomplete]="caAutocomplete"
    />
    <mat-autocomplete #caAutocomplete="matAutocomplete" [displayWith]="displayCasFn">
      <mat-option *ngFor="let ca of cas$ | async" [value]="ca">
        {{ ca.issuerCNs[0] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Пароль носія</mat-label>
    <input matInput type="password" [formControlName]="formFields.PASSWORD" required />
    <button
      color="primary"
      mat-raised-button
      matSuffix
      [disabled]="!form.valid"
      (click)="loadCertificateAndSignIn()"
    >
      <span fxLayout="row" fxLayoutAlign="space-between center">
        <mat-spinner diameter="24" *ngIf="isLoading | async"></mat-spinner>
        <span>Зчитати</span>
      </span>
    </button>
  </mat-form-field>

  <div *ngIf="showError">
    <p>
      Виникла помилка при взаємодії з криптографічною бібліотекою. Бібліотеку web-підпису не
      запущено або не інстальовано у системі. Для продовження необхідно запустити або інсталювати
      бібліотеку web-підпису, та перезавантажити сторінку.
    </p>
    <div>
      <a href="http://iit.com.ua/download/productfiles/EUSignWebInstall.exe"
        >Інсталяційний пакет web-бібліотеки підпису</a
      >
    </div>
    <div>
      <a
        href="https://chrome.google.com/webstore/detail/%D1%96%D1%96%D1%82-%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D1%87-%D1%86%D1%81%D0%BA-1-%D0%B1%D1%96%D0%B1%D0%BB/jffafkigfgmjafhpkoibhfefeaebmccg?utm_source=chrome-app-launcher-info-dialog"
        >Інсталяційний пакет бібліотеки підпису (web-розширення)</a
      >
    </div>
    <div>
      <a href="http://iit.com.ua/download/productfiles/EUSignWebOManual.pdf"
        >Настанова користувача</a
      >
    </div>
  </div>
</form>
