import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {addAuthTokenToRequest} from '@core/commons/utils';
import {AuthorizationService} from '@core/services/services/authorization.service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthorizationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return addAuthTokenToRequest(req, this.authService).pipe(
      switchMap((authReq) => next.handle(authReq)),
    );
  }
}
