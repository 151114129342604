import {BehaviorSubject, defer, Observable} from 'rxjs';
import {distinctUntilChanged, finalize} from 'rxjs/operators';

import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class BlockUntilResponseService {
  private blockStateSubject$ = new BehaviorSubject(false);
  private _activeRequests = [];

  blockState$ = this.blockStateSubject$.asObservable().pipe(distinctUntilChanged());

  loadingStart() {
    this._activeRequests.push(0);
    if (this._activeRequests.length === 1) {
      this.blockStateSubject$.next(true);
    }
  }

  loadingEnds() {
    this._activeRequests.pop();
    if (this.isEmptyLoadingArray()) {
      this.blockStateSubject$.next(false);
    }
  }

  private isEmptyLoadingArray(): boolean {
    return this._activeRequests.length === 0;
  }

  execute = <T>(source: Observable<T>): Observable<T> =>
    defer(() => {
      this.loadingStart();
      return source.pipe(finalize(() => this.loadingEnds()));
    });
}
