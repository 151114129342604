import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, Component} from '@angular/core';

import {AuthorizationService} from '@core/services/services/authorization.service';

@Component({
  selector: 'fury-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  mobileQuery: MediaQueryList;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private authorizationService: AuthorizationService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
  }

  logOut() {
    this.authorizationService.eraseAuthData();
  }
}
