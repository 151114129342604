import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as QRCode from 'qrcode';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit, OnDestroy {
  @Input() deeplink: string;
  @Input() width: number;
  @Input() set isResetTimer(isResetTimer: boolean) {
    isResetTimer && this.resetTimer();
  }
  @Output() isDeeplinkExists = new EventEmitter<boolean>();

  private subscription: Subscription;
  public timeLeft: string;
  private totalSeconds = 180;
  private remainingSeconds: number;

  ngOnInit(): void {
    this.generateQrCode(this.deeplink)
      .then((url) => {
        this.startTimer();
      })
      .catch((err) => {
        console.error('Error generating QR code', err);
      });
  }

  generateQrCode(text: string): Promise<string> {
    return QRCode.toDataURL(text);
  }

  startTimer() {
    this.remainingSeconds = this.totalSeconds;
    this.updateTimeDisplay();
    const source = interval(1000);
    this.subscription = source.subscribe(() => {
      if (this.remainingSeconds > 0) {
        this.remainingSeconds--;
        this.updateTimeDisplay();
      } else {
        this.subscription.unsubscribe();
        this.isDeeplinkExists.emit(null);
      }
    });
  }

  resetTimer() {
    this.remainingSeconds = this.totalSeconds;
    this.updateTimeDisplay();
  }

  updateTimeDisplay() {
    this.timeLeft = this.formatTime(this.remainingSeconds);
  }

  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const secs: number = seconds % 60;
    return `${this.padNumber(minutes)}:${this.padNumber(secs)}`;
  }

  padNumber(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  get isQrAlreadyExists(): boolean {
    return this.timeLeft !== '00:00';
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
