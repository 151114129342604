import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SharedModule} from '@shared/shared.module';

import {LayoutComponent} from './layout.component';

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule],
  declarations: [LayoutComponent],
})
export class LayoutModule {}
