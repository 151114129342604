import {ChangeDetectionStrategy, Component} from '@angular/core';

import {EnvironmentType} from '@core/types';
import {environment} from '@environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  environment = environment;

  get isHeaderBlueWithoutLogo(): boolean {
    return [
      EnvironmentType.UAT,
      EnvironmentType.SANDBOX,
      EnvironmentType.STAGE,
      EnvironmentType.KHR_AWS_PROD,
    ].includes(environment.type);
  }
}
