<mat-dialog-content>
  <div class="header">
    <div class="title">
      <span>Увага!</span>
    </div>
    <div class="action">
      <button mat-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="content">
    <p>
      В електронному кабінеті проводяться оновлювальні роботи!
    </p>
    <p>
      Заповнення електронної анкети для отримання учнівського квитка тимчасово неможливе.
    </p>
    <p>
      Отримати учнівський можна заповнивши паперову анкету, роздрукувавши її за посиланням та надати
      класному керівнику Вашого навчального закладу:
      <span (click)="openTerms('survey')" class="terms-link">
        http://surl.li/sfapi
      </span>
    </p>
    <p>
      Також заповнену анкету Ви можете надіслати у чат підтримки КП «єДніпро» за посиланням:
      <span (click)="openTerms('ednipro')" class="terms-link">
        https://t.me/ednipro_help
      </span>
    </p>
  </div>
</mat-dialog-content>
