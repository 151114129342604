import {Injectable} from '@angular/core';

export enum StorageKey {
  AUTH_TOKEN = 'AUTH_SERVICE_AUTH_TOKEN',
}

@Injectable({providedIn: 'root'})
export class StorageService {
  getItem(key: StorageKey): string | null {
    return localStorage.getItem(key);
  }

  setItem(key: StorageKey, data?: string) {
    if (data) {
      localStorage.setItem(key, data);
    } else {
      this.removeItem(key);
    }
  }

  removeItem(key: StorageKey) {
    localStorage.removeItem(key);
  }
}
