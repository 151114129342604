<mat-toolbar color="primary" fxLayoutAlign="space-between center">
  <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
  <button mat-button (click)="logOut()">Вийти</button>
</mat-toolbar>
<mat-sidenav-container fxFlex="1">
  <mat-sidenav
    #snav
    [opened]="!mobileQuery.matches"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
    fixedTopGap="56"
  >
    <mat-nav-list>
      <a mat-list-item routerLink="/dashboard">Налаштування</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
