<h2 mat-dialog-title>Як отримати ЄЦП</h2>
<mat-dialog-content>
  <p>
    Отримати Електронно-цифровий підпис можна через Приватбанк чи будь-який акредитований центр
    сертифікації ключів (АЦСК), для оформлення знадобиться паспорт громадянина України та довідка
    про присвоєння ідентифікаційного номера.
  </p>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Для користувачів Приват Банку</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <p>
          Якщо ви є клієнтом Приватбанку, можете отримати ЕЦП через систему Приват24.
        </p>
        <p>
          1. Зайдіть у меню «Усі послуги»/«Бізнес»/«Завантажити сертифікат».
        </p>

        <img class="pb1" src="assets/images/pb1.png" alt="" lightbox [simpleMode]="true" />
        <p>
          2. Заповніть заявку на отримання сертифіката. Зверніть увагу, що поле «E-mail» обов’язкове
          для заповнення. Зверніть увагу на написання прізвища, імені та по-батькові – вони мають
          співпадати с паспортними даними. Не забудьте перевірити точність написання електроної
          пошти з дотриманням прописних літер.
        </p>
        <p>
          Після перевірки даних натисніть одну з кнопок: «Дані вірні» або «Дані невірні».
        </p>

        <img class="pb2" src="assets/images/pb2.png" alt="" lightbox [simpleMode]="true" />
        <p>
          3. Оберіть директорію, в якій у вас є права на запис, для створення в ній файлу – сховища
          ключів.
        </p>
        <img class="pb3" src="assets/images/pb3.png" alt="" lightbox [simpleMode]="true" />
        <p>
          4. Створіть пароль на файл – сховище ключів. Мінімальна довжина паролю – 8 символів, він
          може включати у себе букви латинського алфавіту та цифри.
        </p>
        <img class="pb4" src="assets/images/pb4.png" alt="" lightbox [simpleMode]="true" />
        <p>
          5. На наступній сторінці пароль, що надійшов у SMS або мобільному додатку Privat24.
          Встановіть галочку навпроти пункту «Я ознайомлений та згоден з Умовами та правилами
          надання банківських послуг і підтверджую коректність даних відправлених мною» та натисніть
          «Далі».
        </p>
        <img class="pb5" src="assets/images/pb5.png" alt="" lightbox [simpleMode]="true" />
        <p>
          6. Заявка на отримання сертифіката сформується автоматично. З’явиться вікно з відповідним
          повідомленням та інформацією щодо сертифіката.
        </p>
        <img class="pb6" src="assets/images/pb6.png" alt="" lightbox [simpleMode]="true" />
      </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Для інших</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <p>
          Ви можете отримати ЕЦП через будь-який з центрів реєстрації. Для цього треба буде виконати
          4 кроки:
        </p>
        <ul>
          <li>зібрати документи;</li>
          <li>зареєструватися в одному з Центрів сертифікації;</li>
          <li>
            прийти в Центр з документами та чистим носієм інформації (флеш-пам'ять чи диск DVD-R);
          </li>
          <li>за допомогою фахівців згенерувати ключі та записати їх на носій.</li>
        </ul>
        <p><strong>Які документи вам знадобляться:</strong></p>
        <ul>
          <li>
            Реєстраційна картка (для фізичної особи) встановленого зразка, заповнена та підписана у
            двох примірниках;
          </li>
          <li>
            Копія паспорта підписувача (копії 1-2 сторінок (3-6 за наявності відміток) та сторінка з
            відміткою про реєстрацію місця проживання) або копія паспорта виготовленого у формі
            картки, що містить безконтактний електронний носій (копії лицьового та зворотного боку
            та копія паперового витягу з Держреєстру про адресу реєстрації місця проживання, або
            копія Довідки про реєстрацію місця проживання фізособи), або паспорта громадянина
            України для виїзду за кордон з відміткою про постійне місце проживання в іноземній
            державі, засвідчена підписом власника;
          </li>
          <li>
            Копія картки платника податків, засвідчена підписом власника.. За наявності у паспорті
            реєстраційного номера облікової картки платника податків відповідно до вимог Положення
            про реєстрацію фізосіб у Держ реєстрі фізосіб – платників податків, затвердженого
            наказом Міндоходів від 10.12.2013 №779, замість копії картки платника податків може бути
            подана копія сторінки паспорта з відповідною відміткою. <br />
            (У разі, якщо через релігійні переконання фізична особа відмовилась від реєстраційного
            номеру облікової картки платника податків, копія картки платника податків не подається,
            замість цього подається копія сторінки паспорту з відміткою про таку відмову).
          </li>
          <li>
            Для іноземних громадян: копія посвідчення на постійне (тимчасове) місце проживання,
            засвідчена підписом власника, або паспорт громадянина іншої країни із нотаріально
            засвідченим перекладом на українську мову.
          </li>
        </ul>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button color="primary" mat-flat-button mat-dialog-close>ОК</button>
</mat-dialog-actions>
