import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {PasswordRecovery} from './../../../core/types';

@Component({
  selector: 'app-password-recovery-modal',
  templateUrl: './password-recovery-modal.component.html',
  styleUrls: ['./password-recovery-modal.component.scss'],
})
export class PasswordRecoveryModalComponent {
  constructor(
    public dialogRef: MatDialogRef<PasswordRecoveryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordRecovery,
  ) {}
}
