import {
  CertificationCenter,
  EuSignCpService,
  IdName,
  ScriptGroupsName,
  UserCertificateInfo,
} from '@innovations28/e-sign';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EMPTY, Observable} from 'rxjs';
import {map, startWith, switchMap, tap} from 'rxjs/operators';

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

import {buildHardwareESignForm} from '@core/commons/forms';
import {BlockUntilResponseService} from '@core/services/services/local/block-until-response.service';
import {NotificationService} from '@core/services/services/notification.service';
import {HardwareESignFormFields} from '@core/types';

@UntilDestroy()
@Component({
  selector: 'app-hardware-e-sign',
  templateUrl: './hardware-e-sign.component.html',
})
export class HardwareESignComponent implements OnInit {
  @Input() clientId: string;
  @Input() clientClassifier: string;
  @Output() userCertificateInfo = new EventEmitter<UserCertificateInfo>();

  cas$: Observable<CertificationCenter[]>;
  mediaTypes$: Observable<IdName[]> = EMPTY;
  mediaDevices$: Observable<IdName[]> = EMPTY;
  form: FormGroup;
  formFields = HardwareESignFormFields;

  sameId = (val1, val2): boolean => val1 && val2 && val1.id === val2.id;

  showError = false;

  isLoading = this.blockUntilResponseService.blockState$.pipe(
    tap((value) => {
      if (value) {
        this.form.disable({emitEvent: false});
      } else {
        this.form.enable({emitEvent: false});
      }
    }),
  );

  constructor(
    private formBuilder: FormBuilder,
    private euSignCpService: EuSignCpService,
    private notificationService: NotificationService,
    public blockUntilResponseService: BlockUntilResponseService,
  ) {}

  ngOnInit(): void {
    this.form = buildHardwareESignForm(this.formBuilder);

    this.cas$ = this.euSignCpService.getAvailableCAs().pipe(
      switchMap((cas) => {
        return this.certificateAuthorityField.valueChanges.pipe(
          startWith(''),
          map((caName) => (typeof caName === 'string' ? caName : caName.issuerCNs[0])),
          map((caName) => (caName ? this.filterCasArray(cas, caName) : cas)),
        );
      }),
    );

    this.euSignCpService
      .initByScriptGroup(ScriptGroupsName.TOKEN_EU_SIGN_LIB)
      .pipe(this.blockUntilResponseService.execute)
      .subscribe(
        () => {},
        (error) => {
          console.log(error);
          this.notificationService.notify(error.message);
          this.showError = true;
        },
        () => {
          this.mediaTypes$ = this.euSignCpService.mediaTypes().pipe(
            switchMap((mediaTypes) => {
              return this.mediaTypeField.valueChanges.pipe(
                startWith(''),
                map((mediaTypeName) =>
                  typeof mediaTypeName === 'string' ? mediaTypeName : mediaTypeName.name,
                ),
                map((mediaTypeName) =>
                  mediaTypeName ? this.filterIdNameArray(mediaTypes, mediaTypeName) : mediaTypes,
                ),
              );
            }),
            // this.blockUntilResponseService.execute,
          );
        },
      );

    this.mediaTypeField.valueChanges.subscribe((newMediaType: IdName) => {
      this.mediaDevices$ = this.euSignCpService.mediaDevices(newMediaType).pipe(
        tap((devices) => {
          if (devices && devices.length > 0) {
            this.mediaDeviceField.setValue(devices[0]);
          }
        }),
        switchMap((mediaDevices) => {
          return this.mediaDeviceField.valueChanges.pipe(
            startWith(''),
            map((mediaDeviceName) =>
              typeof mediaDeviceName === 'string' ? mediaDeviceName : mediaDeviceName.name,
            ),
            map((mediaDeviceName) =>
              mediaDeviceName
                ? this.filterIdNameArray(mediaDevices, mediaDeviceName)
                : mediaDevices,
            ),
          );
        }),
      );
    });

    this.certificateAuthorityField.valueChanges
      .pipe(
        switchMap((ca: CertificationCenter) =>
          this.euSignCpService
            .setCertificateAuthorityForHardware(ca)
            .pipe(this.blockUntilResponseService.execute),
        ),
      )
      .subscribe((result) => {});
  }

  private filterCasArray(array: CertificationCenter[], name: string): CertificationCenter[] {
    return array.filter(({issuerCNs}) => issuerCNs[0].toLowerCase().includes(name.toLowerCase()));
  }

  private filterIdNameArray(array: IdName[], name: string): IdName[] {
    return array.filter((entity) => entity.name.toLowerCase().includes(name.toLowerCase()));
  }

  displayIdNameFn(entity: IdName): string {
    return entity?.name || '';
  }

  displayCasFn(entity: CertificationCenter): string {
    return entity?.issuerCNs?.length ? entity.issuerCNs[0] : '';
  }

  get hardwarePassword(): string {
    return this.form.get(HardwareESignFormFields.PASSWORD).value;
  }

  get certificateAuthorityField(): FormControl {
    return this.form.get(HardwareESignFormFields.CERTIFICATE_AUTHORITY) as FormControl;
  }

  get mediaTypeField(): FormControl {
    return this.form.get(HardwareESignFormFields.MEDIA_TYPE) as FormControl;
  }

  get mediaType(): IdName {
    return this.mediaTypeField.value;
  }

  get mediaDeviceField(): FormControl {
    return this.form.get(HardwareESignFormFields.MEDIA_DEVICE) as FormControl;
  }

  get mediaDevice(): IdName {
    return this.mediaDeviceField.value;
  }

  loadCertificateAndSignIn() {
    this.euSignCpService
      .getCertificateInfoByHardware({
        typeIndex: this.mediaType.id,
        deviceIndex: this.mediaDevice.id,
        password: this.hardwarePassword,
      })
      .pipe(untilDestroyed(this), this.blockUntilResponseService.execute)
      .subscribe(
        (result: UserCertificateInfo) => {
          this.notificationService.notify('Ключ успішно завантаженно');
          this.userCertificateInfo.emit(result);
        },
        (error) => {
          const message = error.message?.length
            ? error.message
            : 'Помилка бібліотеки ЕЦП, оновіть сторінку';
          this.notificationService.notify(message);
        },
      );
  }
}
