<form [formGroup]="form" fxLayout="column">
  <mat-form-field>
    <mat-label>Центр сертифікації</mat-label>
    <input
      type="text"
      matInput
      [formControlName]="formFields.CERTIFICATE_AUTHORITY"
      [matAutocomplete]="caAutocomplete"
    />
    <mat-autocomplete #caAutocomplete="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let ca of cas$ | async" [value]="ca">
        {{ ca.issuerCNs[0] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field>
    <ngx-mat-file-input
      #removableInput
      placeholder="Файл ЕЦП"
      required
      [formControlName]="formFields.E_SIGN_FILE"
    ></ngx-mat-file-input>
    <mat-hint align="end"
      ><span
        class="ecp-hint"
        (click)="openModalWithInstructionsOfGettingEcp()"
        matTooltip="Відкрити інструкції з отримання ЄЦП"
      >
        <mat-icon class="ecp-hint-icon">info_outline</mat-icon><span> Як отримати ЕЦП</span>
      </span></mat-hint
    >
    <button
      type="button"
      mat-icon-button
      matSuffix
      *ngIf="!removableInput.empty"
      (click)="removableInput.clear($event)"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Пароль ЕЦП</mat-label>
    <input matInput type="password" [formControlName]="formFields.PASSWORD" required />
    <button
      [disabled]="!form.valid"
      color="primary"
      mat-raised-button
      matSuffix
      (click)="loadCertificateAndSignIn()"
    >
      <span fxLayout="row" fxLayoutAlign="space-between center">
        <mat-spinner
          color="accent"
          class="loading-spinner"
          diameter="24"
          *ngIf="isLoading | async"
        ></mat-spinner>
        <span>Зчитати</span>
      </span>
    </button>
  </mat-form-field>
</form>
